import React from 'react';
import { getStatusBadge } from './utils';

const InviteCodeCard = ({ invite, onRevokeClick }) => {
    const status = getStatusBadge(invite);

    return (
        <div className="card">
            <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                    <h5 className="card-title mb-1">Code: {invite.code}</h5>
                    <p className="card-text text-muted small mb-0">
                        Message: {invite.message}
                    </p>
                    <p className="card-text text-muted small mb-0">
                        Created: {new Date(invite.created_at).toLocaleString()}
                    </p>
                    {invite.used_by && (
                        <p className="card-text text-muted small mb-0">
                            Used by: {invite.used_by}
                        </p>
                    )}
                </div>

                <div className="d-flex align-items-center gap-3">
                    <button
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => onRevokeClick(invite.code)}
                        disabled={invite.revoked || invite.is_expired}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                             fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                        </svg>
                    </button>

                    <span className={`badge ${status.color}`}>
                        {status.text}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default InviteCodeCard;